@media screen and (max-width: 600px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    width: 100vw;

    .container {
      width: calc(100vw - 1rem);

      .comic-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .comic {
          width: 80vmin !important;
          position: static !important;
          height: 80vmin !important;
        }
      }

      .compare {
        flex-direction: column;

        img {
          margin: 0.8rem 0;
          width: 70%;
        }
      }

      .side {
        width: calc(100vw - 1rem);
      }

      .wrap-right {
        position: static;
        width: calc(100vw - 1rem);
      }

      .sampling-img {
        width: calc(100vw - 1rem) !important;
        position: static;
      }

      p {
        margin: 3rem 1rem !important;
        width: initial !important;
      }

      .rm-mobile {
        display: none;
      }

      .floating {
        margin: 0;
      }

      #python-add {
        width: calc(100vw - 1rem);
      }

      #screen {
        width: calc(100vw - 1rem);
      }

      .author {
        flex-direction: column;

        .spacer {
          background-color: #fff;
        }

        h3,
        div:not(.spacer) {
          margin: 1rem;
        }

        .logos {
          font-size: 4rem !important;
        }
      }
    }
  }

  :root {
    font-size: 32pt;
  }
}
